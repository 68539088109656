import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useTranslation as useI18Translation } from 'react-i18next'

const useTranslation = () => {
  const { i18n } = useI18Translation()
  const { search } = useLocation()
  const lang = new URLSearchParams(search).get('lang')

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [lang])
}

export default useTranslation
